import React, { FC } from 'react';
import styled from './imageCard.module.scss';
import { IImageCard } from '../../types';


const ImageCard: FC<IImageCard> = ({ image, model, price }) => {
    return (
        <div className={styled.card}>
            <div className={styled.modelName}>{model}</div>
            <div className={styled.cardImage}>
                <img src={image} />
            </div>
            <div className={styled.cardPrice}>
                <p className={styled.priceName}>Стоимость техники</p>
                <p className={styled.price}>
                    {new Intl.NumberFormat('ru-RU', {
                        style: 'currency',
                        currency: 'RUB',
                    }).format(price)}
                </p>
            </div>
        </div>
    );
};

export default ImageCard;