import React, { forwardRef } from 'react';
import Select from 'react-select';
import st from './ControlledSelect.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { ISelectProps } from '../../../types';

const ControlledSelect = forwardRef<HTMLInputElement, ISelectProps>(
    ({
         label,
         options,
         placeholder,
         name,
         error,
     }, ref) => {
        const styles = {

            control: (styles, state) => ({
                ...styles,

                fontStyle: 'normal',
                width: '100%',
                background: '#FFF',
                borderRadius: '24px',
                border: '1px solid #DFDFDF',
                outline: 'none',
                boxShadow: 'none',
                paddingInlineEnd: '20px',
                color: '#999',

                '&:focus': {
                    border: '1px solid #DFDFDF',
                    outline: 'none',
                },
                '&:active': {
                    border: '1px solid #DFDFDF',
                    outline: 'none',
                },


            }),
            valueContainer: styles => ({
                ...styles,
                padding: '12px 20px',
                outline: 'none',
                color: '#999',

            }),

            indicatorSeparator: () => ({
                display: 'none',
            }),
            clearIndicator: styles => ({
                display: 'none',
            }),

            input: (base, state) => ({
                ...base,
                borderColor: '#DFDFDF',
                fontStyle: 'normal',
                fontSize: '16px',
                lineHeight: '24px',
                transform: '  0.2s ease-out',
                padding: '0',
                color: '#333',

            }),
            placeholder: (styles, state) => ({
                ...styles,
                fontStyle: 'normal',
                fontSize: '16px',
                lineHeight: '24px',
                color: '#757575',
            }),

            menu: styles => ({
                ...styles,
                zIndex: '10',
                background: '#FFF',
                width: '100%',
                minWidth: '200px',
                borderRadius: '20px',
                opacity: '1',
                padding: '24px',
            }),
            option: (styles, state) => ({
                ...styles,
                fontStyle: 'normal',
                fontSize: '16px',
                lineHeight: '24px',
                transform: '  0.2s ease-out',
                background: 'transparent',
                color: '#999',
            }),
            dropdownIndicator: (base, state) => ({
                ...base,
                transform: state.isFocused ? 'rotate(180deg)' : null,
            }),
            singleValue: (styles, state) => ({
                ...styles,
                color: '#333',

            }),

        };
        const { control } = useFormContext();

        return (

            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                    <>

                        <div className={st.wrap}>
                            <p className={st.label}>
                                {label}
                            </p>
                            <Select
                                ref={ref}
                                options={options}
                                classNamePrefix='select'
                                styles={styles}
                                placeholder={placeholder}
                                value={options ? options.find(c => c.value === value) : null}
                                onChange={choice => onChange(choice.value)}
                            />
                            {error && <p className={'error'}>{error}</p>}

                        </div>
                    </>
                )}
            />

        );
    });

export default ControlledSelect;