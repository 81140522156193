import React from 'react';
import './styles/index.scss';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { NotificationContainer } from 'react-notifications';
import "react-notifications/lib/notifications.css";


function App() {
    return (
        <div className='App'>
            <RouterProvider router={router} />
            <NotificationContainer />
        </div>
    );
}

export default App;
