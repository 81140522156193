import React, { forwardRef } from 'react';
import { useWatch } from 'react-hook-form';
import styled from './controlledChip.module.scss';
import { IChip } from '../../../types';


const ControlledChip = forwardRef<HTMLInputElement, IChip>(
    ({
         value,
         text,
         watch,
         ...register
     }, ref) => {
        const watchName = watch;
        const simpleFormActiveName = useWatch({ name: watchName });
        const isActive = simpleFormActiveName === value.toString();
        return (
            <label>
                <div className={`${styled.box} ${isActive ? styled.box_active : ''}`}>
                    <p>{text}</p>
                    <input type='radio' value={value} ref={ref} {...register} />
                </div>
            </label>
        );
    });
export default ControlledChip;