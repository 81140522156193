import React from 'react';
import styled from './bankLogo.module.scss'
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { IBankLogo } from '../../types';



const BankLogo = ({items}:IBankLogo) => {
    return (
        <span className={styled.wrap}>
      {items.map((item:any) => (
          <>
              <div
                  key={item.name}
                  className={styled.bank}
                  data-tooltip-id={item.name}
                  data-tooltip-content={item.name}
                  data-tooltip-place="top"
              >
                  <img src={item.logo_small} alt={item.name} />
              </div>
              <Tooltip id={item.name} />
          </>
      ))}
    </span>
    );
};

export default BankLogo;
