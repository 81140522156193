import React, { FC, useMemo } from 'react';
import styled from './calculationBlock.module.scss';
import { useFormContext } from 'react-hook-form';
import ControlledChip from '../controlledComponents/controlledChip/controlledChip';
import BankLogo from '../BankLogo/BankLogo';
import ControlledSlider from '../controlledComponents/controlledSlider/controlledSlider';
import { getBid, GetMinMaxPercent, GetMountPay } from '../../utils';
import { ICalcBlock } from '../../types';


const CalculationBlock: FC<ICalcBlock> = ({ creditData, banks, programs }) => {
    const { register, watch, getValues, setValue } = useFormContext();




    const min = useMemo(() => {
        return GetMinMaxPercent(programs, getValues('term')).minValue;

    }, [watch('term')]);

    const max = useMemo(() => {
        return GetMinMaxPercent(programs, getValues('term')).maxValue;
    }, [watch('term')]);


    return (
        <div className={styled.block}>
            <div className={styled.block__head}>
                <h3>Условия</h3>
            </div>
            <div className={styled.block__row}>
                <p className={styled.block__subTitle}>
                    Срок кредита
                </p>
                <div className={styled.block__chips}>
                    {creditData.map((item: any) =>
                        <ControlledChip
                            value={parseInt(item)}
                            text={`${item} мес.`}
                            watch={'term'}
                            {...register('term')}
                        />)}
                </div>

            </div>
            <div className={styled.block__amount}>
                <ControlledSlider
                    percent={{
                        min: min,
                        max: max,
                    }}
                />
            </div>

            <div className={styled.block__result}>
                <div>
                    <p className={styled.label}>Ежемесячный платеж</p>
                    <p> {GetMountPay(
                        getValues('percent'),
                        getBid(programs, getValues('percent'), getValues('term')),
                        getValues('price'),
                        getValues('term'),
                    )}</p>

                </div>
                <div>
                    <p className={styled.label}>Ставка</p>
                    <p className={styled.value}>
                        {getBid(programs, getValues('percent'), getValues('term'))} %{' '}
                    </p>
                </div>
                <div>
                    <p className={styled.label}>Доступные банки</p>
                    <BankLogo items={banks} />
                </div>

            </div>

            <p className={styled.prompt}>
                Расчёт носит информационный характер и не является публичной офертой.
            </p>


        </div>);
};

export default CalculationBlock;