import { createBrowserRouter } from 'react-router-dom';
import RetailWidget from '../pages/Retail/RetailWidget';
import DistributorWidget from '../pages/Distributor/DistributorWidget';
import DistributorButtonWidget from '../pages/DistributorButtonWidget/DistributorButtonWidget';

export const router = createBrowserRouter([
    { path: '/', element: <RetailWidget /> },
    { path: '/distribute', element: <DistributorWidget /> },
    { path: '/custom', element: <DistributorButtonWidget /> },
]);
