export const GetMinMaxPercent = (data: any[], term: any) => {
    if (!data && !term) return;

    const sortArray = data.filter(item => item.term_to === parseInt(term));

    const maxValue = Math.max(...sortArray.map(item => item.initial_pay_to));

    const minValue = Math.min(...sortArray.map(item => item.initial_pay));

    return { maxValue, minValue };
};

export const getFirstPay = (price: number, percent: number): number => {
    return Math.round((price * percent) / 100);
};

export const GetMountPay = (percent, bid, price, term) => {
    const firstPay = Math.round((price * percent) / 100);

    const sumOfCredit = price - firstPay;

    let creditPercent = parseInt(bid) / 100;

    if (isNaN(creditPercent)) {
        creditPercent = 0;
    }

    let monthPay = 0;

    if (bid == 0) {
        monthPay = Math.round(sumOfCredit / parseInt(term));
    } else {
        let n = parseInt(term);
        let i = creditPercent / 12;
        let annuitetKoeff = (i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1);
        monthPay = Math.round(annuitetKoeff * sumOfCredit);
    }

    return new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
    }).format(monthPay);
};

export const getBid = (arr, percent, term) => {
    let termArray = arr.reduce(function (acc, item) {
        if (item.term_to === parseInt(term)) {
            acc.push(item);
        }
        return acc;
    }, []);

    let program = termArray.find(
        elem => percent >= elem.initial_pay && percent <= elem.initial_pay_to
    );

    if (program) {
        return program.marketing_bid;
    }
};
