import { IMaskInput } from 'react-imask';
import { createRef, forwardRef } from 'react';
import styled from './CtrMaskInput.module.scss';
import { IMaskProps } from '../../../types';


const MaskedTextField = forwardRef<HTMLInputElement, IMaskProps>(
    (props, inputRef) => {
        const { onChange, mask, label, error, ...other } = props;
        const ref = createRef();

        return (
            <div className={styled.box}>
                {label &&
                    <p className={styled.label}>
                        {label}
                    </p>}
                <IMaskInput
                    {...other}
                    inputRef={inputRef}
                    ref={ref}
                    mask={mask}
                    className={styled.input}
                    onAccept={(value: any) => {
                        onChange({ target: { name: other.name, value } });
                    }}
                />
                {error && <p className={'error'}>{error}</p>}
            </div>

        );
    },
);

export default MaskedTextField;