import React, { forwardRef } from 'react';
import styled from './CtrInput.module.scss';
import { ICtrInput } from '../../../types';


const CtrInput = forwardRef<HTMLInputElement, ICtrInput>((
    {
        label,
        placeholder,
        error,
        ...register
    }, ref) => {
    return <div className={styled.box}>
        {label &&
            <p className={styled.label}>
                {label}
            </p>
        }
        <input className={styled.input} ref={ref} {...register} />
        { error && <p className={'error'}>{error}</p>}
    </div>;
});

export default CtrInput;