import React, { useEffect, useMemo, useState } from 'react';
import styled from '../simpleForm/simpleForm.module.scss';
import ControlledSelect from '../controlledComponents/ControledSelect/ControledSelect';
import axios from 'axios';
import { useFormContext } from 'react-hook-form';
import { api } from '../../config/api';

interface Props {
    custom?: boolean;
}

const SelectForm = ({ custom }: Props) => {
    const [data, setData] = useState([]);
    const {
        watch,
        getValues,
        formState: { errors },
    } = useFormContext();
    const queryParameters = new URLSearchParams(window.location.search);
    const hash = queryParameters.get('hash');

    useEffect(() => {
        async function fetchData() {
            if (custom) {
                await api
                    .get(`/api/dealers/by-brand?hash=${hash}`)
                    .then(res => setData(res.data?.data.regions));
            } else {
                await api
                    .get(`/api/dealers/by-model?hash=${hash}`)
                    .then(res => setData(res.data?.data.regions));
            }
        }

        fetchData();
    }, []);

    const regions = useMemo(() => {
        const array = data.map(item => {
            return { label: item.name, value: item.name };
        });
        return array;
    }, [data]);

    const city = useMemo(() => {
        if (!data && typeof getValues('regions') === 'undefined') return [];
        const city = data.find(elem => elem.name === getValues('regions'));
        const array = city?.cities.map(item => {
            return { label: item.name, value: item.name };
        });
        return array;
    }, [watch('regions')]);

    const dealer = useMemo(() => {
        if (!data) return [];
        const cities = data.find(elem => elem.name === watch('regions'));
        const city = cities?.cities.find(elem => elem.name === watch('city'));
        const dealer = city?.dealers.map(item => {
            return { label: item.name, value: item.hash };
        });
        return dealer;
    }, [watch('city')]);

    return (
        <div className={styled.form}>
            <ControlledSelect
                label={'Регион'}
                options={regions}
                placeholder={'Выберете регион'}
                name={'regions'}
                error={errors?.regions?.message}
            />

            <ControlledSelect
                label={'Город'}
                options={city}
                placeholder={'Выберете город'}
                name={'city'}
                error={errors?.city?.message}
            />
            <ControlledSelect
                label={'Мотосалон'}
                options={dealer}
                placeholder={'Выберете мотосалон'}
                name={'dealer'}
                error={errors?.dealer?.message}
            />
        </div>
    );
};

export default SelectForm;
