import React, { FC, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from '../calculationBlock/calculationBlock.module.scss';
import ControlledChip from '../controlledComponents/controlledChip/controlledChip';
import ControlledSlider from '../controlledComponents/controlledSlider/controlledSlider';
import { getBid, GetMinMaxPercent, GetMountPay } from '../../utils';
import BankLogo from '../BankLogo/BankLogo';
import CtrInput from '../controlledComponents/CtrInput/CtrInput';
import MaskedTextField from '../controlledComponents/maskInput/CtrMaskInput';
import { NotificationManager } from 'react-notifications';
import { ICalcBlock } from '../../types';
import axios from 'axios';
import { api } from '../../config/api';

const CreditContact: FC<ICalcBlock> = ({
    creditData,
    programs,
    banks,
    customSend = false,
}) => {
    const {
        register,
        watch,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useFormContext();
    const [isDisabled, setDisabled] = useState(false);

    const min = useMemo(() => {
        return GetMinMaxPercent(programs, getValues('term')).minValue;
    }, [watch('term')]);

    const max = useMemo(() => {
        return GetMinMaxPercent(programs, getValues('term')).maxValue;
    }, [watch('term')]);

    const onSubmit = data => {
        setDisabled(true);

        if (customSend) {
            api.post('/api/order/create-with-custom-price', {
                client: {
                    fio: data.name,
                    phone: data.phone,
                },
                product: data.model,
                initial_pay_amount: data.initialPay,
                credit_amount: data.price,
                term: data.term,
                is_brand_calc: 1,
                source: 60,
                hash: data.dealer,
            })
                .then(() => {
                    NotificationManager.success(
                        'Ваша заявка отправлена',
                        'Отправлено'
                    );
                    setTimeout(() => {
                        window.location.href = document.referrer;
                    }, 10000);
                })
                .catch(() => {
                    NotificationManager.error('Что-то пошло не так', 'Ошибка');
                });
        } else {
            api.post('/api/order/create-order', {
                client: {
                    fio: data.name,
                    phone: data.phone,
                },
                product: data.model,
                initial_pay_amount: data.initialPay,
                price: data.price,
                term: data.term,
                is_brand_calc: 1,
                source: 60,
                hash: data.dealer,
            })
                .then(() => {
                    NotificationManager.success(
                        'Ваша заявка отправлена',
                        'Отправлено'
                    );
                    setTimeout(() => {
                        window.location.href = document.referrer;
                    }, 10000);
                })
                .catch(() => {
                    NotificationManager.error('Что-то пошло не так', 'Ошибка');
                });
        }
    };
    return (
        <div className={styled.block}>
            <div className={styled.block__head}>
                <h3>Условия</h3>
            </div>
            <div className={styled.block__info}>
                {!customSend && (
                    <div className={styled.block__row}>
                        <p className={styled.block__subTitle}>Срок кредита</p>
                        <div className={styled.block__chips}>
                            {creditData.map((item: any) => (
                                <ControlledChip
                                    value={parseInt(item)}
                                    text={`${item} мес.`}
                                    watch={'term'}
                                    {...register('term')}
                                />
                            ))}
                        </div>
                    </div>
                )}

                <div className={styled.block__amount}>
                    <ControlledSlider
                        percent={{
                            min: min,
                            max: max,
                        }}
                    />
                </div>

                <div className={styled.block__result}>
                    <div>
                        <p className={styled.label}>Ежемесячный платеж</p>
                        <p>
                            {GetMountPay(
                                getValues('percent'),
                                getBid(
                                    programs,
                                    getValues('percent'),
                                    getValues('term')
                                ),
                                getValues('price'),
                                getValues('term')
                            )}
                        </p>
                    </div>
                    <div>
                        <p className={styled.label}>Ставка</p>
                        <p className={styled.value}>
                            {getBid(
                                programs,
                                getValues('percent'),
                                getValues('term')
                            )}{' '}
                            %{' '}
                        </p>
                    </div>
                    <div>
                        <p className={styled.label}>Доступные банки</p>
                        <BankLogo items={banks} />
                    </div>
                </div>
            </div>
            <div className={styled.block__contact}>
                <div className={styled.block__head}>
                    <h3>Контактные данные</h3>
                </div>
                <div className={styled.block__fields}>
                    <CtrInput
                        label={'Имя'}
                        placeholder={'Введите ФИО'}
                        {...register('name')}
                        error={errors?.name?.message}
                    />
                    <MaskedTextField
                        mask={'+{7}(000)-000-00-00'}
                        label={'Номер телефона'}
                        {...register('phone')}
                        error={errors?.phone?.message}
                    />
                </div>

                <div className={styled.block__submit}>
                    <button
                        type={'submit'}
                        className={styled.btn}
                        onClick={handleSubmit(onSubmit)}
                        disabled={isDisabled}
                    >
                        Отправить заявку
                    </button>
                    <p className={styled.notice}>
                        Заполняя форму и нажимая кнопку отправить заявку, я
                        принимаю условия передачи информации
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CreditContact;
