import styled from './contolledSlider.module.scss';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ICControlledSlider } from '../../../types';
import { NotificationManager } from 'react-notifications';

const controlledSlider = forwardRef<HTMLInputElement, ICControlledSlider>(
    ({ percent }, ref) => {
        const { watch, setValue } = useFormContext();
        const [stop, setStop] = useState(false);
        const { getValues } = useFormContext();

        const initialPay = useMemo(() => {
            return (getValues('price') / 100) * getValues('percent');
        }, [getValues('percent')]);

        const outPutRef = useRef(null);
        const sliderRef = useRef(null);
        const percentValue = watch('percent');
        const outputDiv = outPutRef.current;
        let target = sliderRef.current;
        const handleOutput = () => {
            if (!stop) {
                outputDiv.style.left =
                    ((target.value - target.min) / (target.max - target.min)) *
                        90 +
                    '% ';
            }
        };

        useEffect(() => {
            if (!target) return;
            handleOutput();
        }, [watch('percent'), watch('term')]);

        useEffect(() => {
            setStop(false);
        }, [watch('term')]);

        useEffect(() => {
            if (stop) {
                NotificationManager.warning(
                    `Нельзя выбрать меньший процент для срока ${watch(
                        'term'
                    )}мес`,
                    '',
                    2000
                );
            }
        }, [stop]);

        return (
            <div className={styled.wrap}>
                <div className={styled.head}>
                    <p className={styled.label}>Первоначальный взнос</p>
                    <p className={styled.initialPay}>
                        {initialPay.toLocaleString('ru')} ₽
                    </p>
                </div>

                <div className={styled.sliderWrap}>
                    <input
                        className={styled.rangeSlider}
                        defaultValue={10}
                        value={percentValue}
                        name={'range'}
                        type='range'
                        min={5}
                        max={98}
                        ref={sliderRef}
                        onChange={event => {
                            if (
                                parseInt(event.target.value) >= percent.min &&
                                parseInt(event.target.value) <= percent.max
                            ) {
                                setStop(false);
                                setValue('percent', event.target.value);
                                handleOutput();
                            } else {
                                setStop(true);
                                event.preventDefault();
                            }
                        }}
                    />
                    <output
                        className={styled.output}
                        ref={outPutRef}
                    >
                        {percentValue}
                    </output>
                </div>
            </div>
        );
    }
);
export default controlledSlider;
